import React from 'react';
import { useParams } from 'react-router-dom';
import Content from '../../components/Content';
import styled, { css } from 'styled-components';
import { Container, Panel, Row, RowTitle, ButtonAction } from '../../components/Form';
import { FormInput, ButtonRow, TextArea, FormSelect } from '../../components/FormRef/Form';
import { Table, Thead, Tbody } from '../../components/Table';

// import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
// import MiniAdd from '../../assets/images/buttons/button-mini-add.svg';
import buttonSend from '../../assets/images/buttons/button-send.svg';
import { MdRemoveCircle } from "react-icons/md";

//**Icons */
import { FaRegTrashAlt  } from "react-icons/fa";
import { FaCloudDownloadAlt } from "react-icons/fa";

// import { FiFileText } from 'react-icons/fi'; //FiFileText
import MiniAdd from '../../assets/images/buttons/button-mini-add.svg';
import { FormContext } from '../../components/FormRef/FormContext'
import { GlobalContext } from '../../contexts/global';
import AuthContext from '../../contexts/auth';
import { ajax, serialize, formData } from '../../api/chequer';
import { toast } from 'react-toastify';

//**Utilidades */
import Mask from '../../services/mask';
import Moment from '../../services/Moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);
const baseURL = process.env.REACT_APP_API_KEY;

export default function PlanningProductStore() {
  return (
    <Content>
      <ProdileContent/>
    </Content>
  );
}

function ProdileContent() {
  const { id } = useParams();
  const { fields } = React.useContext(FormContext);
  const [fileName, setFileName] = React.useState(null);
  const [stage, setStage] = React.useState(0);
  const [loading, setLoading] = React.useState(0);
  const { roleScan } = React.useContext(GlobalContext);
  const { user } = React.useContext(AuthContext);
  const [stateButton, setStateButton] = React.useState(false);
  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [checkedProduct, setCheckedProduct] = React.useState([]);
  const [isView, setIsView] = React.useState(false);
  const [isViewWait, setIsViewWait] = React.useState(false);
  const [textWait, setTextWait] = React.useState("Atualizando Dados");
  const { todayAddDay } = Moment();
  const [formTotalValue, setFormTotalValue] = React.useState([]);
  const [today] = React.useState(todayAddDay({ format: 'yyyy/mm/dd' }))
  const [status, setStatus] = React.useState(null)
  const modalRef = React.useRef(null);
  const [regiao] = React.useState([{ name: 'Norte', value: 'norte' },
    { name: 'Noroeste', value: 'noroeste' },
    { name: 'Sul', value: 'sul' },
    { name: 'Centro', value: 'centro_oeste' },
    { name: 'Serrana', value: 'serrana' },
    { name: 'Escritório', value: 'escritorio' },])

  //**Permissões */
  // const aDmin = React.useMemo(() => roleScan(), [roleScan]);
  // const cr_Register = React.useMemo(() => roleScan(['cr_products']), [roleScan]);
 
 const dynamicSort = React.useCallback((property)=> {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {        
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
 }, [])

const checkPrazoEvento = React.useCallback(() => {
  //const splitData = fields["start_date"].value.split('-');
  const splitData = today.split('-');
  const d  = new Date(splitData[0], splitData[1], splitData[2]);
  const data = new Date(d.getFullYear(), d.getMonth() - 1, (d.getDate() + 19));

  const field1 = new Date(fields['start_date'].value);
  const field2 = new Date(fields['end_date'].value);

  // const lDate = new Date(e.target.value);   

  if((data >= field1) || (data >= field2)) {
     toast.error("O prazo do evento nao pode ser inferior a 20 dias");
     return false;
  }
  
  return true;
 },[today, fields]);

  function startWait(text="Atualizando.") {
    let ponto = ".";
    setTextWait(text);  
    return setInterval(() => {
      setTextWait(`${text}${ponto}`);            
        ponto += ".";
        if(ponto === ".....") ponto = "";
    }, 1000);
  }

  //**Envia dados do usuário para atualização no servidor */
  const handleSubmit = React.useCallback(async (e)=> {
    e.preventDefault();
    let file = false;    
    // if(!checkPrazoEvento()) {
    //   return
    // }

    setStateButton(true);
    setIsViewWait(true);
    const interval = startWait("Atualizando Dados");

    function unFormat(ev) {
      return ev.replace(".", "").replace(",", ".");
    }

    const productAll = document.querySelectorAll('.product');
    const productArray = [];
    [...productAll].forEach((p)=> {
      const orderProductId = p.children[0].dataset.product_order
      productArray.push({ 
        order_product_id: orderProductId !== "" ? orderProductId : null,      
        product_category_id: +p.dataset.category, 
        product_id: p.children[0].value, 
        codigo: p.children[1].children[0].value, 
        name: p.children[2].children[0].value, 
        quantity_day: p.children[3].children[0].value,
        quantity_total: p.children[4].children[0].value,
        value_unit: unFormat(p.children[5].children[0].value),
        value_total: unFormat(p.children[6].children[0].value),
        unit_of_measurement: p.children[7].children[0].value,
      })
    })


    //console.log(productArray)
    //return false
    const params = [     
      { name: 'user', value: {name: user.name, id: user.id, user_function: user.user_function}},
      {name: 'user_region', value: fields.user_region.value},
      { name: 'technical_manager', value: fields.technical_manager.value },
      { name: 'event_place', value: fields.event_place.value },
      { name: 'event_name', value: fields.event_name.value },
      { name: 'hour_start', value: fields.hour_start.value },
      { name: 'hour_end', value: fields.hour_end.value },
      { name: 'start_date', value: fields.start_date.value },
      { name: 'end_date', value: fields.end_date.value },
      { name: 'event_duration', value: +fields.event_duration.value },
      { name: 'number_of_participants', value: +fields.number_of_participants.value },
      { name: 'status', value: fields.status.value },
      {name: 'price_total', value: unFormat(fields.price_total.value)},
      {name: 'observations', value: fields.observations.value},
      {name: "event_description", value: fields.event_description.value},
      { name: 'order_products', value: productArray },  
    ];

    if (fields['upload_archive'].value.trim() !== '') {
      params.push({ name: 'upload_archive', type: 'file', value: fields['upload_archive'].files });  
      file = true;
    }
    
    const body = await serialize(params);
    const response = await ajax({ url: '/order-service/' + id, method: 'put', body, json: true });     

    if(response.success) {
      setTextWait("Atualizado com sucesso!")
      setTimeout(() => {
        clearInterval(interval)
        setIsViewWait(false)     
      }, 1000);
    }
    
    if(file) {
      params.push({ name: 'id', value: id });
      const body = await formData(params);
      await ajax({ url: '/uploadfile', method: 'post', body }); 
      if (response.error) return toast.error(response.error);
      if (response.success && response.message) toast.success(response.message);       
      file = false;
    }else{
      if (response.error) return toast.error(response.error);
      setIsViewWait(false)
      if (response.success && response.message) toast.success(response.message); 
      
    }   

    if (response.error) {
      setStateButton(false);    
      return toast.error(response.error)
    };
    
    
     
    setTimeout(() => {
      setStateButton(false);      
    }, 1000);
  
  }, [fields, id, user, textWait, checkPrazoEvento]);

  //** Click da Checkbox do modal */
  const handleCheckBox = React.useCallback((e)=> {  
      const id = e.target.dataset.id; // id do produto  
      const categoryId = e.target.dataset.category; // id da categoria 
      const newCategory = categoryOptions.filter((f) => +f.value !== +categoryId);
      const category = categoryOptions.filter((f) => +f.value === +categoryId);
      const categoryEdited = category.filter((c)=> c.products.map((p)=> {
        if(p.id === +id) {  
          if(p.checked === true) {          
            p.checked = !p.checked 
            const tds = formTotalValue.filter((i) => +i.id !== +p.id);          
            setFormTotalValue(tds)
          }else {         
            p.checked = !p.checked;
            p.value_total = p.price;
            p.quantity_day = 1;
            setFormTotalValue((old)=> [...old, {id: p.id, price: p.price, quantity_total: 1}]);                  
          }            
        }
        return true
      }));
    
    categoryEdited[0].products.sort(dynamicSort("id"));
    const result = [...categoryEdited, ...newCategory];
    result.sort(dynamicSort("value"));
    setCategoryOptions(result);
    setStage(0)
  },[categoryOptions, formTotalValue, dynamicSort]);
  //** Fecha o modal */
  const CloseModal = React.useCallback((e)=> {    
    if (e.target.classList.contains('open') || e.target.classList.contains('view-close')) {      
      modalRef.current.removeEventListener('click', CloseModal)
      setIsView(false)
    }
  },[]);

  //** Abre o modal */
  const OpenModal = React.useCallback((e)=> {
    e.preventDefault();  
    const id = e.target.dataset.id;
    const result = categoryOptions.filter((item)=> {      
      if(+item.value === +id) return item; 
      return false;     
    });
    setCheckedProduct(result);
    setIsView(true);   
    modalRef.current.addEventListener('click', CloseModal);    
  },[categoryOptions, CloseModal]);

  //** Calcula a quantidade de itens */
  const totalCalculate = React.useCallback((e)=> {
    function unFormat(ev) {
      return ev.replace(".", "").replace(",", ".");
    }
    const totalValue = e.target.offsetParent.nextSibling.nextSibling.children[0];
    const quantity_total = e.target.value;
    const element = e.target.parentElement.parentElement;  
    const id = +element.children[0].value   
    const tds = formTotalValue.filter((i) => +i.id !== id);    
    setFormTotalValue([...tds, {id: id, price: unFormat(element.children[5].children[0].value), quantity_total: quantity_total}]);
    
    const total = unFormat(element.children[5].children[0].value) * +quantity_total;
    totalValue.value = total.toLocaleString('pt-br', {minimumFractionDigits: 2});  
    setStage(0)
  }, [formTotalValue]);

  //** Remove produto */
  const handleRemoveItem = React.useCallback((e)=> {
    function unFormat(ev) {
      return ev.replace(".", "").replace(",", ".");
    }

    const orderProductId = +e.target.dataset.id;

    MySwal.fire({
      title: "Deseja remover?",
      text: "Essa ação não tera mais volta!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Deletar!"
    }).then( async (result) => {
      if (result.isConfirmed) { 
        /* Read more about isConfirmed, isDenied below */    
        let prodId;
        let valorTotal = 0
        const res = categoryOptions.filter((c) => c.products.map((p) => {                      
          if(p.order_product_id === +orderProductId) {  
              valorTotal = unFormat(fields['price_total'].value) - (p.quantity_total * p.price)
              delete p.checked
              delete p.value_total
              delete p.quantity_day
              delete p.quantity_total
              delete p.order_product_id              
              //p.checked = false;
              prodId = p.id;
              fields['price_total'].value = valorTotal.toLocaleString('pt-br', {minimumFractionDigits: 2});
          }
          return p;          
        }));
        // valorTotal.toLocaleString('pt-br', {minimumFractionDigits: 2});
        const tds = formTotalValue.filter((i) => +i.id !== +prodId);          
        setCategoryOptions(res);                    
        setFormTotalValue(tds)
        setStage(3);

        MySwal.fire({
          title: "Removido com sucesso.",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "Ok",
          denyButtonText: `Don't save`
        }).then(async (result) => {
          const priceTotal = unFormat(fields['price_total'].value);          
          await ajax({ url: '/order-service/' + orderProductId + '/ ' + priceTotal + '/destroy', method: 'delete' });                    
        });        
      }
    });
    
  }, [fields, categoryOptions, formTotalValue])


  const handleOrderProduct = React.useCallback(async (array) => {
    
    const response = await ajax({ url: '/order-service/' + id });    
    if(response) {
      //Mask.timeSec(fields['hour_start']);
      //Mask.timeSec(fields['hour_end']);

      fields["price_total"].value = Mask.setMoney(response.price_total);
      fields['user_region'].value = response.user_region;
      fields["technical_manager"].value = response.technical_manager;
      fields["event_place"].value = response.event_place;
      fields["event_name"].value = response.event_name;
      fields["start_date"].value = response.start_date;
      fields["end_date"].value = response.end_date;
      fields["hour_start"].value = response.hour_start;
      fields["hour_end"].value = response.hour_end;
      fields["event_duration"].value = response.event_duration;
      fields["number_of_participants"].value = response.number_of_participants;
      fields["observations"].value = response.observations;
      fields["event_description"].value = response.event_description;
      fields["status"].value = response.status;
      fields["logging"].value = response.logging;
      setStatus(response.status)
      setFileName(response.upload_archive)      
      // if(response.upload_archive !== null) fields["upload_archive"].value = response.upload_archive;

      let newCategory;
      let category;
      let categoryEdited;

      if(response.orderProducts.length >= 1) {        
        response.orderProducts.filter((r) => {          
          newCategory = array.filter((f) => +f.value !== +r.product_category_id);
          category = array.filter((f) => +f.value === +r.product_category_id);
         

          categoryEdited = category.filter((c)=> c.products.map((p)=> {           
            if(r.product_id === p.id) {
              p.checked = true; //!p.checked
              p.value_total = r.value_total;
              p.quantity_day = r.quantity_day;
              p.quantity_total = r.quantity_total;
              p.order_product_id = r.id;  
              setFormTotalValue((old)=> [...old, {id: p.id, price: p.price, quantity_total: r.quantity_total}]);
            }
            return c;
          }));
          return r;
        })   
  
        categoryEdited[0].products.sort(dynamicSort("id"));
        const result = [...categoryEdited, ...newCategory];
        result.sort(dynamicSort("value"));
        
        setCategoryOptions(result);
      }else{
        setCategoryOptions(array);
      }      
      setLoading(1)            
    }
  }, [id, fields, dynamicSort])

  const checkStatus = React.useCallback((fn) => {
    function fnOne(pd) {
      let rt = false;
      for(let i = 0; i < pd.length; i++) {       
        if(pd[i] === status) {
          rt = true
          break;
        }
      }
      return rt;
    }
   
    if(fn === "Producao_tecnica") {      
      const producaoTecnica = ["EM APROVAÇÃO TÉCNICA", "AGUARDANDO REVISÃO", "EM APROVAÇÃO ADM", "RECUSADO", "APROVADO", "VIABILIDADE TÉCNICA"];
      return fnOne(producaoTecnica)
    }

    if(fn === "Diretor_técnico") {      
      const producaoTecnica = ["EM APROVAÇÃO ADM", "AGUARDANDO REVISÃO", "RECUSADO", "EM REVISÃO", "RECUSADO", "APROVADO", "VIABILIDADE TÉCNICA"];
      return fnOne(producaoTecnica)
    }

    if(fn === "Diretor_financeiro") {
      const producaoTecnica = ["RECUSADO", "EM REVISÃO", "RECUSADO", "APROVADO", "VIABILIDADE TÉCNICA"];
      return fnOne(producaoTecnica)
    }
  }, [status])

  const scanPermission = React.useCallback((array) => {
    if( checkStatus(user.user_function) ) return false;
    return roleScan(array);
  },[roleScan, user, checkStatus]);

  // const checkPermissiom = React.useCallback((evento, end)=> {    
  //   if(loading === 1) {      
  //     console.log(scanPermission(evento))
  //     if(scanPermission(evento) === undefined) {
  //       return {visibility: 'hidden', position: 'absolute'}
  //     }
  //     if(end) setLoading(2)
  //   }    
  // }, [stage, loading,scanPermission])

  const handleOptionStatus = React.useCallback(()=> {   
    const option = [];
    // if(user.user_function === "Supervisor_regional") {
    //   option.push(
    //     { name: 'EM VIABILIDADE TÉCNICA', value: 'EM VIABILIDADE TÉCNICA' },
    //     { name: 'EM REVISÃO', value: 'EM REVISÃO' },
    //     { name: 'RECUSADO', value: 'RECUSADO' },        
    //   )
    // }    
    if(user.user_function === "Gestor") {
      option.push(       
        { name: 'EM APROVAÇÃO TÉCNICA', value: 'EM APROVAÇÃO TÉCNICA' },
        { name: 'EM REVISÃO', value: 'EM REVISÃO' },
        { name: 'AGUARDANDO REVISÃO', value: 'AGUARDANDO REVISÃO' },
        { name: 'RECUSADO', value: 'RECUSADO' },
        // { name: 'AGUARDANDO O.S', value: 'AGUARDANDO O.S' },
        { name: 'EM APROVAÇÃO ADM', value: 'EM APROVAÇÃO ADM' },
        { name: 'APROVADO', value: 'APROVADO' },
      )
    }    
    if(user.user_function === "Produçao_técnica") {
      option.push(       
        { name: 'EM APROVAÇÃO TÉCNICA', value: 'EM APROVAÇÃO TÉCNICA' },        
      )
    } 
    if(user.user_function === "Diretor_técnico") {
      option.push(       
        { name: 'EM APROVAÇÃO ADM', value: 'EM APROVAÇÃO ADM' },       
        { name: 'AGUARDANDO REVISÃO', value: 'AGUARDANDO REVISÃO' }, 
        { name: 'RECUSADO', value: 'RECUSADO' },
      )
    }  
    if(user.user_function === "Produçao_executiva") {
      option.push(       
        { name: 'EM APROVAÇÃO TÉCNICA', value: 'EM APROVAÇÃO TÉCNICA' },
        { name: 'EM REVISÃO', value: 'EM REVISÃO' },
        { name: 'AGUARDANDO REVISÃO', value: 'AGUARDANDO REVISÃO' },              
        { name: 'EM APROVAÇÃO ADM', value: 'EM APROVAÇÃO ADM' },
        { name: 'RECUSADO', value: 'RECUSADO' },
        { name: 'APROVADO', value: 'APROVADO' },
      )
    } 
    if(user.user_function === "Diretor_financeiro") {
      option.push(           
        { name: 'APROVADO', value: 'APROVADO' },
        { name: 'RECUSADO', value: 'RECUSADO' },
      )
    }  
    return option;
  },[user])  

  const removeFileName = React.useCallback((e) => {
    MySwal.fire({
      title: "Remover o arquivo?",
      text: "Essa ação não tera mais volta!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Deletar!"
    }).then( async (result) => {
      if (result.isConfirmed) {  
        const response = await ajax({ url: '/uploadfile/' + fileName + '/' + id, method: 'delete' });
        if(response.message) {            
          MySwal.fire(response.message);
          return false;
        }

        if(response.success) {
          MySwal.fire(response.message);            
        }

        MySwal.fire({
          title: "Removido com sucesso.",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "Ok",
          denyButtonText: `Don't save`
        }).then((result) => {
         setFileName(null)
        });         
        
      }
    });    
  },[fileName, id]);


  React.useEffect(()=> {    
    if(stage === 0) {     
      if(fields['price_total'] !== undefined) {
        if(formTotalValue.length >=1) {
          let valorTotal = 0;  
          if(formTotalValue && formTotalValue.length >= 1) {        
            formTotalValue.map((p)=> {
              const total = p.price * +p.quantity_total;
              valorTotal = +valorTotal + +total;
              return p;
            });       
          }      
          fields['price_total'].value = valorTotal.toLocaleString('pt-br', {minimumFractionDigits: 2});
          if(scanPermission(['price_view'])) toast.warning("Total dos items R$ "+ fields['price_total'].value);
        }else{
          fields['price_total'].value = "0,00";
        }  
      }
      setStage(2)
    }
    
    // Cleanup side effect
    // return () => {
    //   setTotalPrices("0,00");
    //   setFormTotalValue([]);
    // };
  }, [fields, stage, formTotalValue, scanPermission]);

  React.useEffect(() => {  
   if(stage === 2 && loading === 0) {    
    async function fetchProductCategory() {   
      return new Promise(async (resolve)=> {
        function dynamicSort(property) {
          var sortOrder = 1;
          if(property[0] === "-") {
              sortOrder = -1;
              property = property.substr(1);
          }
          return function (a,b) {
              /* next line works with strings and numbers, 
               * and you may want to customize it to your needs
               */
              var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
              return result * sortOrder;
          }
        }

        const response = await ajax({ url: `/product/category` });        
        const options = [];
        if(response.rows) response.rows.map((item) => options.push({value: item.id, name: item.name, products: item.products.sort(dynamicSort("id"))}));
        if(fields['price_total'] !== undefined) handleOrderProduct(options.sort(dynamicSort("value")))
        //setCategoryOptions(options.sort(dynamicSort("value")));
        resolve(true);
      });               
    }
    
    (async function() {      
      await fetchProductCategory();   
    })(); 
    setStage(3)
   }
    
  }, [fields, stage, loading, handleOrderProduct]);

  return (
    <Container>
      <form id="forming" onSubmit={handleSubmit}>
        <Panel style={scanPermission(['total_view', 'ver_status_projeto']) ? {} : {visibility: 'hidden', position: 'absolute'}}>
          <RowTitle text="TOTAL / STATUS"/>
          <FormRow>
            {scanPermission(['total_view']) ? (
              <RowSelect col={3}>
                <FormInput 
                  required={true}
                  inputMode="numeric"
                  text="VALOR TOTAL.."
                  col={12}
                  name="price_total"
                  defaultValue={"0,00"}
                  readOnly={true}             
                />
              </RowSelect>
            ): (
              <RowSelect col={3} style={{visibility: 'hidden', position: 'absolute'}}>
                <FormInput 
                  required={true}
                  inputMode="numeric"
                  text="VALOR TOTAL.."
                  col={12}
                  name="price_total"
                  defaultValue={"0,00"}
                  readOnly={true}             
                />
              </RowSelect>
            )}

            {scanPermission(['ver_status_projeto']) ? (
              
              <RowSelect col={4}>
                <FormSelect 
                  label="Status.."
                  options={handleOptionStatus()} 
                  col={12}            
                  name="status"                
                  // firstText="Selecione..."           
                />   
              </RowSelect>
            ): (
              <RowSelect col={4} style={{visibility: 'hidden', position: 'absolute'}}>
                <FormSelect 
                  label="Status.."
                  options={handleOptionStatus()} 
                  col={12}            
                  name="status"                
                  // firstText="Selecione..."           
                />   
              </RowSelect>
            )}

                               
          </FormRow>
          {scanPermission(['up_project']) && (
            <Row>
              <ButtonAction type="submit" src={buttonSend} alt="mini" disabled={stateButton} />              
            </Row>
            )
          }
        </Panel>       
        <Panel>
          <RowTitle text="DADOS DA ATIVIDADE">
            {/* <FaClipboardList size={25} /> */}
          </RowTitle>
          <TextArea col={12} text="Descrição do evento.." name="event_description" wrap="off" rows={7} className="noResize width99" style={{ marginTop: "10px"}}/>
          <FormSelect 
            label="Regiao do projeto.."
            options={regiao} 
            col={2}
            md={4}
            lg={3}
            name="user_region"
            firstText="Selecione..."
            //onChange={handleChange}
          />
          <FormInput
            required={true}            
            text="Responsável Técnico.."
            col={2}
            md={4}
            lg={3}
            name="technical_manager"
            defaultValue={""}
            //readOnly={checkOnly()}
          />
          <FormInput
            required={true}            
            text="Local do Evento.."
            col={2}
            md={4}
            lg={3}
            name="event_place"
            defaultValue={""}
            readOnly={scanPermission(['up_project']) ? false : true}
          />
          <FormInput
            required={true}            
            text="Ação/Método/Evento.."
            col={2}
            md={4}
            lg={3}
            name="event_name"
            defaultValue={""}
            readOnly={scanPermission(['up_project']) ? false : true}
          />
          <FormInput
            required={true}
            type="date"
            inputMode="numeric"
            text="Data / Horário de início.."
            defaultValue={""}
            col={2}
            md={4}
            lg={3}
            name="start_date"
            readOnly={scanPermission(['up_project']) ? false : true}
            // onChange={checkPrazoEvento}
          />
          
          <FormInput
            required={true}
            type="date"
            inputMode="numeric"
            text="Data / Horário de encerramento.."
            defaultValue={""}
            col={2}
            md={4}
            lg={3}
            name="end_date"
            readOnly={scanPermission(['up_project']) ? false : true}
            // onChange={checkPrazoEvento}
          />

          <FormInput
            required={true}
            inputMode="numeric"
            text="Horário / Inicio"
            col={2}
            md={4}
            lg={3}
            name="hour_start"
            defaultValue={""}
            readOnly={scanPermission(['up_project']) ? false : true}
          />
          <FormInput
            required={true}
            inputMode="numeric"
            text="Horário / Final"
            col={2}
            md={4}
            lg={3}
            name="hour_end"
            defaultValue={""}
            readOnly={scanPermission(['up_project']) ? false : true}
          />

          <FormInput
            required={true}
            inputMode="numeric"
            text="Duração do evento (dias).."
            col={2}
            md={4}
            lg={3}
            name="event_duration"
            defaultValue={""}
            readOnly={scanPermission(['up_project']) ? false : true}
          />     

          <FormInput
            required={true}
            inputMode="numeric"
            text="Número de participantes.."
            col={2}
            md={4}
            lg={3}
            name="number_of_participants"
            defaultValue={""}
            readOnly={scanPermission(['up_project']) ? false : true}
          />
          
          <FormInput            
            type="file"
            text=""
            col={2}
            md={4}
            lg={3}
            name="upload_archive"
            defaultValue={""}           
            //readOnly={checkOnly()}
          />
          {fileName && (
            <>
              <Label onClick={removeFileName}><MdRemoveCircle size={25} color='red' cursor={'pointer'} style={{padding: "1px"}}/>Remove</Label>
              <ALink rel="noreferrer" href={`${baseURL}/docs/${fileName}`} target="_blank"><FaCloudDownloadAlt size={25} color='green' cursor={'pointer'} style={{padding: "1px"}}/>Download</ALink>         
            </>
          )}
          
            
          <TextArea col={12} text="Observações.." name="observations" wrap="off" rows={7} className="noResize width99" readOnly={scanPermission(['up_project']) ? false : true}/>
          <TextArea col={12} text="Histórico.." name="logging" wrap="off" rows={7} className="noResize width99" readOnly={true}/>
        </Panel>              
        <Panel id={"products"}>
            {categoryOptions && categoryOptions.map((item) => (
              <FormRow key={item.value}>
                <RowTitle text={item.name.toUpperCase()}>
                  <input type={"hidden"} defaultValue={item.value}/> 
                  <AbsoluteButton>
                    {scanPermission(['up_project']) && (
                      <ButtonRow dataSet={item.value} size={"1.8rem"} top={"0.3rem"} name="btnAdd" src={MiniAdd} alt="mini" onClick={OpenModal}/> 
                    )}                    
                  </AbsoluteButton>                              
                </RowTitle>
                {item.products && item.products.map(fb => (                  
                  fb.checked === true && (
                   
                    <FormRow key={fb.id} id={'products_' + fb.id} className='product' data-category={item.value}>
                      <input name="id" value={fb.id} data-product_order={fb.order_product_id || ""} type='hidden'/>
                      <InputCategory 
                        data-category={item.value}
                        readOnly={true}
                        defaultValue={fb.codigo}                      
                        inputMode="numeric"
                        text="Código.."
                        col={1}
                        name={"codigo" + fb.id}
                      />      
                      <InputCategory
                        readOnly={true}
                        defaultValue={fb.name} 
                        text="Descrição.."
                        col={4}
                        name={"name" + fb.id}                      
                      />   
                      <InputCategory
                        required={true}
                        inputMode="numeric"
                        defaultValue={fb.quantity_day}
                        text="QTD/DIA.."
                        col={1}
                        name={"quantity_day" + fb.id}  
                        readOnly={scanPermission(['up_project']) ? false : true}
                      /> 
                      <InputCategory
                        data-category={item.value}
                        required={true}
                        inputMode="numeric"
                        defaultValue={fb.quantity_total}
                        text="QTD TOTAL.."
                        col={1}
                        name={"quantity_total" + fb.id} 
                        onChange={totalCalculate}  
                        readOnly={scanPermission(['up_project']) ? false : true}                   
                      /> 
                      <InputCategory
                        required={true}
                        className={scanPermission(["price_view"]) ? "" : "hidden"}
                        text="Valor Unit.."
                        col={1}
                        name={"value_unit"}
                        value={Mask.updateMoney(fb.price)} 
                        readOnly={true} 
                        //onClick={(e) => Mask.money(e.target)}
                      /> 
                      <InputCategory
                        required={true}
                        className={scanPermission(["price_view"]) ? "" : "hidden"}
                        text="Valor Total.."
                        col={2}
                        name={"value_total"}
                        value={Mask.updateMoney(fb.value_total)}
                        readOnly={true}                     
                      /> 
                      <InputCategory
                        readOnly={true}
                        defaultValue={fb.unit_of_measurement} 
                        text="Un. Medida.."
                        col={scanPermission(["price_view"]) ? 2 : 5}
                        name={"unit_of_measurement" + fb.id}                      
                      />
                      {(fb.order_product_id && scanPermission(['up_project'])) && (
                        <RemoveButtom data-id={fb.order_product_id || ""} onClick={handleRemoveItem}>                          
                          <FaRegTrashAlt size={20} />
                        </RemoveButtom>
                      )}
                          
                    </FormRow>
                    )
                ))}              
            </FormRow>
            ))}
         

          {scanPermission(['up_project']) && (
            <Row>
              <ButtonAction type="submit" src={buttonSend} alt="mini" disabled={stateButton} />              
            </Row>
            )
          }
          
        </Panel>
      </form>
      <Modal ref={modalRef} className={isView ? 'open' : ''}>
      <div className="modal-view">
        <div className="view">
          <div className="view-close" onClick={CloseModal}>
            FECHAR [X]
          </div>
          <div className="view-container">
            <Table>
            <Thead>
              <tr className="" style={{backgroundColor: "#6a6969"}}>
                <th scope="col" style={{color: "#fff"}}>#</th>
                <th scope="col" style={{color: "#fff"}}>Código</th>
                <th scope="col" style={{color: "#fff"}}>Item</th>
                {scanPermission(['price_view']) && (                  
                  <th scope="col" style={{color: "#fff"}}>Preço</th> 
                )}                              
              </tr>
            </Thead>
            <Tbody>
              {checkedProduct && checkedProduct.map((item)=> (
                item.products.map((fb)=> (                  
                  <tr key={fb.id}>
                    <td data-label="codigo">
                      <span className="mob">#</span>                      
                      <InputCheckbox defaultChecked={fb.checked || false} data-id={fb.id} data-category={item.value} name={"checkbox"} type='checkbox' onClick={handleCheckBox}/>
                    </td>
                    <td data-label="codigo">
                      <span className="mob">Código</span>
                      <p>{fb.codigo}</p>
                    </td>
                    <td data-label="name">
                      <span className="mob">Item</span>
                      <p>{fb.name}</p>
                    </td>
                    {scanPermission(['price_view']) && (                  
                   <>
                    <td data-label="price">
                      <span className="mob">Preço</span>
                      <p>{fb.price}</p>
                      </td>
                      <td data-label="un. medida">{fb.unit_of_measurement}</td>
                     </>
                    )}                    
                   
                  </tr>
                ))
              ))}     
            </Tbody>
            </Table>
          </div>
        </div>
      </div>
      </Modal>
      <WaitForm className={isViewWait ? 'open' : ''}>
        <h3>{textWait}</h3>
      </WaitForm>      
    </Container>    
  );
}

const WaitForm = styled.div`
${({ theme }) => css`
  position: fixed;
  background-color: #282828e3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out; 
  
  h3 {
    color: #fff;
    font-size: 5em;
  }

  &.open {
    visibility: visible;
    opacity: 1;
    z-index: 999999;
  }  
`}
`

const InputCheckbox = styled.input`
width: 1.5rem;
max-width: 1.5rem;
min-height: 1.5rem;
`
function GroupInputFunction({className, name, type, value, text, ...rest}) {  
  const { registerField } = React.useContext(FormContext);  
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (inputRef.current && name) {
      registerField(name, inputRef.current);
    }   
    
  }, [name, registerField]);
  return (
    <div className={className}>
        <input name={name} type={!type ? 'text' : type} defaultValue={value && value} {...rest} placeholder=" " autoComplete="off" {...rest}/>                       
        <span className="control-group__highlight"/>
        <span className="control-group__bar"/>
        <label className="control-group__label">{text}</label>        
    </div>
  )
}

const InputCategory = styled(GroupInputFunction)`
position: relative;
display: flex;
flex-direction: column;
margin-bottom: 1.7rem;
justify-content: flex-end;
padding: 0 .5rem;
overflow: unset;

&.hidden {
  display: none;
}

@media only screen and (max-width: 75em) {
    padding: 0 5px;
}


input[type=email],
input[type=password],
input[type=date],
input[type=text] {
    background-color: unset;
    font-size: .9rem;
    padding: 5px 10px 5px 5px;
    display: block;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-radius: none;
    box-shadow: none;     
    color: #7376a2;
    font-weight: 500;
    text-transform: uppercase;

    @media only screen and (max-width: 37.5em) {
        font-size: 1rem;
    }

    &:focus {
        outline: none;
        border: none;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &:read-only {
        cursor: not-allowed;
        user-select: none;
    }
}

label {
    color: #999;
    font-size: 1rem;       
    font-weight: normal;
    position: absolute;
    left: 20px;
    pointer-events: none;
    transform-origin: 0 0;
    transition: 0.4s ease all;
    -moz-transition: 0.4s ease all;
    -webkit-transition: 0.4s ease all;
    bottom: 7px;
    overflow: unset;    
}

input[type=email]::-ms-input-placeholder,
input[type=password]::-ms-input-placeholder,
input[type=date]::-ms-input-placeholder,
input[type=text]::-ms-input-placeholder {
    color: transparent;
}

input[type=email]::placeholder,
input[type=date]::placeholder,
input[type=password]::placeholder,
input[type=text]::placeholder {
    color: transparent;
}

input[type=email]:not(:placeholder-shown) ~ label,
input[type=date]:not(:placeholder-shown) ~ label,
input[type=password]:not(:placeholder-shown) ~ label,
input[type=text]:not(:placeholder-shown) ~ label {
    bottom: 1.5rem;
    font-size: 0.7rem;
    color: #5264ae;
    left: 0.8rem;

    @media only screen and (max-width: 37.5em) {
        bottom: 1.6rem;  
        left: 0.6rem;
    }   
}

.control-group__highlight {
    position: absolute;
    height: 100%;
    width: 100%;    
    left: 0;
    pointer-events: none;
    opacity: 0.15;
}

&:last-child .control-group__highlight {
    // height: calc(100% - 1.7rem);
    @media only screen and (max-width: 37.5em) {
        height: 100%;
    }
}

/* active state */
input[type=email]:focus ~ .control-group__highlight,
input[type=date]:focus ~ .control-group__highlight,
input[type=password]:focus ~ .control-group__highlight,
input[type=text]:focus ~ .control-group__highlight {
    -webkit-animation: inputHighlighter 0.6s ease;
    -moz-animation: inputHighlighter 0.6s ease;
    animation: inputHighlighter 0.6s ease;
}

input[type=email]:read-only ~ .control-group__highlight,
input[type=date]:read-only ~ .control-group__highlight,
input[type=password]:read-only ~ .control-group__highlight,
input[type=text]:read-only ~ .control-group__highlight {
    animation: none;
}

.control-group__bar {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    transform: translateY(-2px);
    border-bottom: 1px solid #cecece;
}

.control-group__bar:before,
.control-group__bar:after {
    content: "";
    height: 2px;
    width: 0;
    position: absolute;
    background: #2305b4;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.control-group__bar:before {
    left: 50%;
}
.control-group__bar:after {
    right: 50%;
}

/* active state */
input[type=text]:focus ~ .control-group__bar {
    border-bottom: none;
}

input[type=text]:focus ~ .control-group__bar:before,
input[type=text]:focus ~ .control-group__bar:after {
    width: 50%;
}

input[type=text]:read-only ~ .control-group__bar:before,
input[type=text]:read-only ~ .control-group__bar:after {
    background: #cecece;
    top: 1px;
    height: 0.5px;
    width: 50%;
}

width: calc(100% / 12 * ${props => props["col"]});


@media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${props => props["lg"]});
}

@media only screen and (max-width: 75em) {
    width: calc(100% / 12 * ${props => props["md"]});
}   

@media only screen and (max-width: 50em) {
    width: 100%;
    width: calc(100% / 12 * ${props => props["xs"]});
}
`

const FormRow = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: inherit;
`;

const RowSelect = styled.div`  
  display: flex;
  width: 100%;  

  width: calc(100% / 12 * ${props => props["col"]});


  @media only screen and (min-width: 75em) {
      width: calc(100% / 12 * ${props => props["lg"]});
  }

  @media only screen and (max-width: 75em) {
      width: calc(100% / 12 * ${props => props["md"]});
  }   

  @media only screen and (max-width: 50em) {
      width: 100%;
      width: calc(100% / 12 * ${props => props["xs"]});
  }
`;
const RemoveButtom = styled.div`
  position: absolute;
  right: 28px;
  cursor: pointer;
  color: red;
  svg {
    pointer-events: none;
  }
`;

const AbsoluteButton = styled.div`
  position: absolute;
  right: 0;
  top: 40%;
  margin-top: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
`;

const Label = styled.label`
display: flex;
align-items: center;
margin-top: -30px;
cursor: pointer;
color: #06c;
border: 1px solid #cecece;
padding: 0 5px;
border-radius: 4px;
height: 30px;
&:hover {
    color: red;
}
`;

const ALink = styled.a`
display: flex;
align-items: center;
margin-top: -30px;
margin-left: 15px;
cursor: pointer;
color: #06c;
border: 1px solid #cecece;
padding: 0 5px;
border-radius: 4px;
height: 30px;
&:hover {
    color: green;
}
`;

export const Modal = styled.div`
  ${({ theme }) => css`
    position: fixed;
    background-color: #ffffff59;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;   

    &.open {
      visibility: visible;
      opacity: 1;
      z-index: 999;
    }

    & .modal-view {
      position: relative;
      width: 1024px;
      padding: 1rem;
      z-index: 9999;

      & .view {
        position: relative;      

        & .view-close {
          padding: 5px;
          cursor: pointer;
          user-select: none;
          &:hover {
            color: orange;
          }
        }

        & .view-container {
          position: relative;
          z-index: 99999;
          height: 500px;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
          border-radius: 5px;
          overflow: auto;
          background-color: #fff;

          & .left-arrow,
          .right-arrow {
            position: absolute;
            top: 50%;
            z-index: 100;
            transition: all 0.2s ease-in-out;
            cursor: pointer;

            & svg {
              pointer-events: none;
              background-color: #ffffffb2;
              border-radius: 3px;
            }

            &:hover {
              transform: scale(1.1);
            }
          }

          & .left-arrow {
            left: 1rem;
          }

          & .right-arrow {
            right: 1rem;
          }

          & img {
            position: absolute;
            visibility: hidden;
            opacity: 0;
            z-index: -1;
            transition: all 0.2s ease-in-out;

            &.active {
              position: relative;
              visibility: visible;
              opacity: 1;
              z-index: 99;
              left: 0;
            }
          }
        }
      }
    }
  `}
`
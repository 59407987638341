/* eslint-disable react/prop-types */
import React from 'react';
import { useParams } from 'react-router-dom';
import Logo from '../../assets/images/gruposbv.png';
import { Fragment } from 'react';
import { GlobalContext } from '../../contexts/global';
import { createGlobalStyle } from 'styled-components';
import { ajax } from '../../api/chequer';


export default function Print() { 
  const { id } = useParams();
  const { roleScan } = React.useContext(GlobalContext);
  const [stage, setStage] = React.useState(0);
  const [dataSet, setDataSet] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isPageLoaded, setIsPageLoaded] = React.useState(false); //this helps
  // const [category, setCategory] = React.useState("");

  const convertData = React.useCallback((data)=> {
    const newData = data.split("-");
    return newData[2] + "/" + newData[1] + "/" + newData[0]
  },[])

  const convertPrice = React.useCallback((money)=> {
    const price = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(money)
    return price
  },[])

  const scanPermission = React.useCallback((array) => {   
    return roleScan(array);
  },[roleScan]);

  React.useEffect(() => {
    if(stage === 0) {
      async function fetchService() {
        const response = await ajax({ url: '/order-service/' + id });
        let category = "";
        const productCategory = [];        
        response.orderProducts.forEach((item) => {          
          if(item.productCategory.name !== category) {
            productCategory.push({name: item.productCategory.name, products: [{id: item.id, codigo: item.codigo, name: item.name, quantity_day: item.quantity_day, quantity_total: item.quantity_total, value_total: item.value_total, value_unit: item.value_unit, unit_of_measurement: item.unit_of_measurement}]});            
          }else{
            productCategory.forEach((c) => {
              if(c.name === item.productCategory.name) {
                c.products = [...c.products, {id: item.id, codigo: item.codigo, name: item.name, quantity_day: item.quantity_day, quantity_total: item.quantity_total, value_total: item.value_total, value_unit: item.value_unit, unit_of_measurement: item.unit_of_measurement}];               
              }              
            })
          }          
          category = item.productCategory.name;
        })
        response.categories = productCategory;        
        setDataSet(response);             
      }

      (async function() {      
        await fetchService();
        setIsLoaded(true);
      })();     
      
      const btnPrint = document.querySelector('#btn-print');
      btnPrint.addEventListener('click', function () {
        window.print();
      });
      setStage(1);
    }
  }, [stage, id])

  
  React.useEffect(() => {
    if (isLoaded) {
      const t1 = document.getElementById("t1")
      const t2 = document.getElementById("t2")
      setTimeout(function(){
        if(t1 !== null) {
          t1.style.cssText = 'min-height:37px; height: 37px;';        
          t1.style.cssText = 'height:' + t1.scrollHeight + 'px';
        }
        if(t2 !== null) {
          t2.style.cssText = 'min-height:37px; height: 37px;';        
          t2.style.cssText = 'height:' + t2.scrollHeight + 'px';
        }
      },0);
      setIsPageLoaded(true);
    }
  }, [isLoaded]);

  return (
    <Fragment>
    <GlobalStyle />
    <FixedHeader className="fixed-header print" />
    {dataSet && <Wrap className="wrap first" data={dataSet} convertData={convertData} convertPrice={convertPrice} scanPermission={scanPermission}/>}
  </Fragment>
  );
}

const FixedHeader = ({ className, children }) => (
  <div className={className}>
    <div className="fixed-title">
      <h6>ORDEM DE SERVIÇO</h6>
    </div>
    <div className="buttons">
      <a href="/project">
        <img src="/images/buttons/button-mini-back.svg" alt="Boltão voltar" />
      </a>
      <button id="btn-print">
        <img src="/images/buttons/button-mini-print.svg" alt="Boltão voltar" />
      </button>
    </div>
    {children}
  </div>
);

const Wrap = ({ className, data, children, convertData, convertPrice, scanPermission}) => (  
  <Fragment>   
    <div className={className}>
      <div className="content">
        <div>
          <header className="wrap-header">
            <div className="logotipo">
              <img src={Logo} alt="logotipo" />
              <h1>ORDEM DE SERVIÇO</h1>
            </div>            
            <div className='header-title'>
              <h2>DADOS DA ATIVIDADE</h2>
            </div>
            <div className='header-body'>
              <ul className='first'>
                <li>Local: <b>{data.event_place}</b></li>
                <li>Técnico Responsável: <b>{data.technical_manager}</b></li>
                <li>Ação/Método/Evento: <b>{data.event_name}</b></li>
                <li>Data / Horário de início: <b>{convertData(data.start_date)}</b></li>
                <li>Data / Horário de encerramento: <b>{convertData(data.end_date)}</b></li>
                <li>Inicio do evento: <b>{data.hour_start}</b></li>
                <li>Final do evento: <b>{data.hour_end}</b></li>  
                <li>Duração do evento (dias): <b>{data.event_duration}</b></li>
                <li>Número de participantes: <b>{data.number_of_participants}</b></li>                               
              </ul>
              <ul className='second'>
                <li>O.S Nº: <b># {data.id}</b></li>
                {scanPermission(['total_view']) && (
                  <>
                    <li>VALOR TOTAL: <b>{convertPrice(data.price_total)}</b></li>
                  </>
                )}                
              </ul>                              
                {data.observations && (
                  <div className='tree'>
                    <span>Observações</span>
                    <textarea id='t1' value={data.observations}>{data.observations}</textarea>
                  </div>
                )}
                {data.event_description && (
                  <div className='tree'>
                    <span>Descrição do evento</span>
                    <textarea id='t2' value={data.event_description}>{data.event_description}</textarea>
                  </div>
                )} 
            </div>
          </header>
          <div className="table-content">
            {data.categories.map((item, s) => 
              <div key={s}>
                <div className='table-category'>{item.name}</div>
                <table>              
                  <thead>
                    <tr>
                      <th>CÓD</th>
                      <th style={{width: "500px"}}>ITEM</th>
                      <th>QTD/DIA</th>
                      <th>QTD/TOTAL</th>
                      {scanPermission(['price_view']) && (
                        <>
                          <th>VALOR UNIT.</th>                  
                          <th>VALOR TOTAL</th>
                          <th>UN. MEDIDA</th>
                        </>
                      )}                      
                    </tr>
                  </thead>
                  <tbody>
                    {item.products.map((p, i)=>
                      <tr key={i}>
                        <td>{p.codigo}</td>
                        <td>{p.name}</td>
                        <td>{p.quantity_day}</td>
                        <td>{p.quantity_total}</td>
                        {scanPermission(['price_view']) && (
                        <>
                          <td>{convertPrice(p.value_unit)}</td>
                          <td>{convertPrice(p.value_total)}</td>
                          <td>{p.unit_of_measurement}</td>
                        </>
                      )}                       
                      </tr> 
                    )}                                
                  </tbody>
                </table>               
              </div>)}                        
          </div>
        </div>
      </div>
    </div>
    {children}
  </Fragment>
);

const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
#root {
    background-color: #525659;
}

body {
    font-family: Helvetica, Arial, sans-serif;
    background-color: rgb(82, 86, 89) !important;
}

img {
    display: block;
    width: 100%;
}

.back {
    background-color: #fff;
    padding: 5px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-left: 10px;
    font-size: 10pt;
    transform: translate(-35px,-1px);
}
.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 15mm;
    background-color: rgb(50, 54, 57);
    box-shadow: 1px 1px 5px #000;
    color: #fff;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    z-index: 10000;
}
.buttons {
    display: flex;
    align-items: center;
}

.fixed-header button {
    transform: all;
    background-color: transparent;
    box-shadow: none;
    border: none
}

.fixed-header button img {
    width: 35px;
}

.fixed-header button img:hover {
    transform-origin: center;
    transform: scale(1.1);
    cursor: pointer;
    transition: all .2s;
}

.buttons a {
    margin: 5px;
}
.buttons a img{
    width: 35px;
}

.fixed-header .wrap-center {
    width: 210mm;
    margin: 0 auto;
}

.wrap{
    position: relative;
    margin: 0 auto;
    width: 297mm;
    /* height: 210mm;  */
    height: auto;
    color: #444;
    background-color: #fff;
    margin-bottom: 5mm;
}

.wrap.first {
    margin-top: 17mm;
}

.content {
    padding: 10px;
    background-color: #fff;
}

@media only screen and (max-width: 37.5em) {
    .wrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
    }
}

.wrap-header {
    display: flex;    
    border: 1px solid #DDD;
    border-radius: 2px;
    margin-bottom: 10px;
    flex-wrap: wrap;

    h1 {
      font-size: 24px;
      padding: 50px 10px;
      width: 100%;
    }

    .header-title {      
      background-color: #d7d7d7;
      width: 100%;    

      h2 {
        font-size: 14px;
        padding: 5px 10px;
      }
    }

    .header-body {
      width: 100%;
      display: flex;
      padding: 10px;
      flex-wrap: wrap;

      ul.first {       
        width: calc(100% / 12 * 8);
      }

      ul.first li {
        font-size: 20px;
        padding: 3px 2px;        
      }

      ul.second {
        width: calc(100% / 12 * 4);
      }

      ul.second li {
        font-size: 20px;
        padding: 10px;
        margin: 5px 0;
        border: 1px solid #cecece;        
      }
      .tree {
        position: relative;
        width: calc(100% / 12 * 12);
        margin-top: 20px;        
        border: 1px solid #cecece;

        span {
          font-size: 20px;
          position: absolute;
          top: -13px;
          background-color: #fff;
          left: 10px;
        }

        p {
          font-size: 18px;
          width: 100%;
          resize: none;
          border: none;
          padding: 20px 10px;
          color: #4a4a4a;
          line-height: 1.4
        }
        
        textarea {
          font-size: 16px;
          width: 100%;
          height: 100%;
          resize: none;
          border: none;
          padding: 20px 10px;
          color: #4a4a4a;
        }
      }
    }
    
}

.logotipo {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #cecece;
  padding: 0 10px;
    img {
        width: 100px;
        height: 70px;
    }
}

.information {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -80px;

    h2 {
        width: 100%;
        text-align: center;
        /* margin-left: -70px; */
    }

    p {
        margin: 0 10px;
    }
}

.table-content {
    border: 1px solid #f2f2f2;
    padding: 2px;
    height: 100%;

    .table-category {
      font-size: 20px;
      font-weight: bold;
      padding: 5px 10px;
      background-color: #cecece;
    }
}

.table-content table {
    font-size: 75%;
    border-collapse: separate;
    border-spacing: 0px;
    width: 100%;
}

.table-content table thead tr {
    display: table-row;
    text-align: left;

    &.center{
        text-align: center;
    }

    & th {
        font-size: 16px;
        text-align: left;
        border-width: 0px;
        padding: 0.5em;
        position: relative;
        background: #eee;
        border-color: #bbb;

        &.center{
            text-align: center;
        }
    }
}


.table-content table tbody tr {
    display: table-row;
    text-align: left;

    &.center{
        text-align: center;
    }

    & td {
        font-size: 20px;
        text-align: left;
        border-width: 1px;
        padding: 0.5em;        
        position: relative;
        // border-radius: 0.25em;
        border-style: solid;
        border-color: #ddd;

        &.center{
            text-align: center;
        }

        &.observation {
            width: 70%;
            p{
                display: flex;
            }
        }
    }
}

.tdrow {
    display: flex;
    align-items: center;
    padding: 2px 5px;
    &:not(:last-of-type) {
        border-bottom: 1px solid #DDD;
    }

    & .ref {
       width: 60px;
    }

    & .product {
       /* text-align: center;
       display: flex;
       justify-content: center;  */
       flex: 1
    }
}


@page :left {
    margin: 1mm;
}

@page :right {
    margin: 1mm;
}

@page {
    /* size: A4;     */
    size: auto;
    margin: 2mm;
}

@media print {
    #root {
        background-color: #fff;
    }
    .fixed-header.print {
        display: none;
    }

    .wrap.first {
        margin: 0 !important;
    }

    .wrap{
        position: relative;
        width: calc(297mm - 6px / 1);
        /* height: calc(210mm - 6px / 1); */
        height: auto;
        color: #444;
        background-color: #fff;
        margin: 0 auto;
        top: 5px;
        left: 5px;
    }

    .content {
        padding: 10px;
        width: calc((297mm - 1px) / 1);
        height: auto;
    }

    .page-break {
        page-break-before: always;
    }

    /* tr{
        page-break-after: always;
        display: block;
    } */
}
`;